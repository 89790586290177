.MuiAppBar-root {
    display: flex;
    align-items: center;
}

.MuiToolbar-root {
    display: flex;
    justify-self: space-between;
}

.Header {
    width: 100%;
    justify-content: space-between;
    background-color: #fbdb6e !important;
}

.Header .MuiToolbar-root {
    display: flex;
    width: 100%;
}

.Header .MuiToolbar-root h6 {
    color: rgb(70, 55, 55);
    font-size: 22px;
    font-weight: 600;
    font-family: 'Roboto';
    width: 100%;
    text-align: center;
}

.Logo {
    width: 35px;
}